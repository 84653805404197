<template>
    <div
        type="text"
        class="more-btn"
        @click="handleClick"
    >
        <div class="more-btn-text">更多</div>
        <i class="el-icon-arrow-right"></i>
    </div>
</template>

<script>
export default {
    name: "hm-more",
    methods: {
        handleClick() {
            this.$emit('click')
        }
    },
};
</script>

<style scoped lang="scss">
.more-btn {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 14px;
    right: 23px;
    padding: 0;
    height: 18px;

    .more-btn-text {
        color: #333333;
        line-height: 15px;
    }

    .el-icon-arrow-right {
        font-weight: bold;
        font-size: 16px;
        margin-left: 5px;
        color: #2166ee;
    }
}
</style>
