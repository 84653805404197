<template>
    <div
        class="hm-menu-box"
        @click="clickMenuItem(data)"
    >
        <div class="hm-menu-item">
            <hm-menu-icon
                :url="data.icon"
                :name="data.name"
            />
            <span class="hm-menu-item__name">
                <text-tooltip :content="getTitle(data)" placement="bottom"></text-tooltip>
            </span>
        </div>
    </div>
</template>

<script>
import { getFrameUrl } from "@/xkUI/libs/menu";
import HmMenuIcon from "./hm-menu-icon.vue";
import TextTooltip from "@/components/scrollWrapper/Sub/TableData/TextTooltip.vue";
import * as dd from "dingtalk-jsapi";
export default {
    name: "hm-menu-item",
    components: { HmMenuIcon, TextTooltip },
    props: {
        data: {
            type: Object,
        }
    },
    data() {
        return {
            inDingTalk: false, // 是否是钉钉环境
        };
    },
    created () {
        this.inDingTalk = dd.env.platform !== "notInDingTalk";
    },
    methods: {
        getTitle(data) {
            return data.title || data.menuName
        },
        clickMenuItem(menu) {
            if (menu.isFrame == 1) {
                if (menu.frameUrl) {
                    let frameUrl = getFrameUrl(menu);
                    let promise = new Promise((resolve) => {
                        resolve(frameUrl);
                    });
                    promise.then((res) => {
                        if (this.inDingTalk) {
                            // 是钉钉环境跳转
                            let urlEncodeedParam = encodeURIComponent(res)
                            let newUrl = `https://applink.dingtalk.com/page/link?url=${urlEncodeedParam}&target=workbench`;
                            let a = document.createElement("a")
                            a.href = newUrl
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        } else {

                            // 不是钉钉环境的跳转
                            const _window = window.open();
                            console.log(res,'跳转地址')
                            if (_window) {
                                _window.location.href = res
                            } else {
                                const a = document.createElement("a")
                                a.href = res
                                document.body.appendChild(a)
                                a.click()
                                document.body.removeChild(a)
                            }
                        }
                    });
                } else {
                    this.$message.info("正在建设中，敬请期待！");
                }
            } else {
                this.$router.push({
                    name: menu.name,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.hm-menu-box {
    position: relative;
    width: 110px;
    height: 88px;
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: transparent;

    &:hover {
        transition: all .2s ease;
        box-shadow: 0 0 14px 0 rgba(33, 102, 238, 0.2);
        background-color: #fff;
    }

    &:after {
        content: "";
        position: absolute;
        left: -10px;
        top: 30px;
        width: 1px;
        height: 25px;
        background: #d2d2d2;
        border-radius: 1px;
    }

    &:nth-child(7n + 1) {
        margin-left: 0;

        &:after {
            display: none;
        }
    }
}

.hm-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 4px;

    &__name {
        width: 100%;
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
}
</style>
