<template>
    <img
        :src="getImgUrl()"
        alt=""
        class="menu-icon"
    />
</template>

<script>
export default {
    name: "hm-menu-icon",
    props: {
        url: {
            type: String
        },
        name: {
            type: String
        }
    },
    methods: {
        getImgUrl() {
            if (!this.icon) {
                try {
                    return require(`@/assets/images/home/menu/${this.name}.png`);
                } catch (e) {
                    return require(`@/assets/images/home/menu/default-menu.png`);
                }
            } else {
                return this.icon;
            }
        }
    },
};
</script>

<style scoped lang="scss">
.menu-icon {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    overflow: hidden;
}
</style>
