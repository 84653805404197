<template>
    <div class="calendar-wrap" :style="{'height': !isExpanded ? '309px' : '74px'}">
        <table class="my-calendar" style="border-collapse: separate;border-spacing: 0 2px">
            <thead>
            <tr>
                <th
                    v-for="(item, index) of weekDays"
                    :key="index"
                    class="week-day"
                >
                    {{ item }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) of displayedDays"
                :key="index"
                class="calendar-row"
            >
                <td
                    v-for="(subItem, subIndex) of item"
                    :key="subIndex"
                >
                    <div
                        class="calendar-day"
                        :class="{
                            last: subItem.lastMon,
                            next: subItem.nextMon,
                            rest: subIndex === 0 || subIndex === item.length -1,
                            today: getIsToday(subItem),
                            selected: subItem.fulDate === value,
                        }"
                        @click="handleClick(subItem)"
                    >
                        <span
                            v-if="calendarEvent[subItem.fulDate] && calendarEvent[subItem.fulDate].list && calendarEvent[subItem.fulDate].list.length > 0"
                            class="sessions"
                        />
                        <span class="date">{{ subItem.date }}</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    getDateInfo,
    getFormatDate,
} from '@/libs/Calendar/utils.js';

export default {
    name: "MyCalendar",
    props: {
        value: {
            type: String
        },
        weekDays: {
            type: Array
        },
        chunkedDays: {
            type: Array
        },
        isExpanded: {
            type: Boolean
        },
        calendarEvent: {
            type: Object
        }
    },
    computed: {
        displayedDays() {
            if (!this.isExpanded) {
                return this.chunkedDays
            } else {
                const arr = this.chunkedDays.find(item =>
                    item.some(i => i.fulDate === this.value)
                ) || this.chunkedDays.slice(0, 1)[0];
                return [arr];
            }
        },
    },
    methods: {
        getIsToday(data) {
            const [currentYear, currentMonth, currentDate] = getDateInfo();
            let day = getFormatDate(currentYear, currentMonth, currentDate);
            return day === data.fulDate
        },
        handleClick(data) {
            this.$emit('input', data.fulDate)
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar-wrap {
    overflow: hidden;
    transition: height .3s;
}

.my-calendar {
    width: 330px;
    margin: 0 auto;

    .week-day {
        font-size: 14px;
        color: #333333;
    }

    .calendar-row {
        .calendar-day {
            width: 43px;
            font-size: 16px;
            color: #333333;
            font-weight: bold;
            text-align: center;
            padding: 0;
            box-sizing: border-box;
            border-radius: 3px;
            position: relative;
            margin: 0 7px;
            transition: all .3s;
            border: 1px solid transparent;

            .sessions {
                position: absolute;
                top: 3px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 6px;
                height: 6px;
                background: #2166EE;
                border-radius: 50%;
                transition: all .3s;
            }

            .date {
                line-height: 41px;
            }

            &.today {
                border-color: #009DFF;
            }

            &.rest {
                color: #C1C1C1;
            }

            &.last,
            &.next {
                color: #D9D9D9;
            }

            &.selected,
            &:hover {
                background: #009DFF;
                box-shadow: 0 4px 11px 0 rgba(24, 96, 218, 0.3);
                border-radius: 3px;
                color: #FFFFFF;
                cursor: pointer;

                .sessions {
                    background: #fff;
                }
            }
        }
    }
}
</style>
