<template>
    <div class="update-notice">
         <dialog-wrapper class="first-page-sys-update-notice-dialog" :dialogObj="dialogObj" @close="handleClose">
            <div class="sys-notice-container-wrapper">
                <div class="btn-close" @click="handleClose">
                    <img src="@/assets/images/close-btn.png">
                </div>
              <div class="sys-notice-container" :style="{backgroundImage: 'url('+ imgUrl + notice.noticeImg + '/update-notice-bg.png)'}">
                <div class="sys-notice-body" :style="{backgroundImage: 'url('+ imgUrl + notice.noticeImg + '/update-notice-content-bg.png)'}">
                    <div class="notice-content-tip" :style="{backgroundImage: 'url('+ imgUrl + notice.noticeImg + '/update-notice-tip-bg.png)'}">
                        {{ notice.title }}
                    </div>
                    <div class="sys-notice-content" >
                        <div v-html="notice.content"></div>
                    </div>
                </div>
                <div class="sys-notice-bottom">
                    <div class="sys-notice-bottom-left">
                        <div class="is-konw" @click="changeKnowing">
                            <div class="select-box">
                                <img v-if="notTip" :src="imgUrl + notice.noticeImg + '/selectOn.png'">
                                <img v-else :src="imgUrl + notice.noticeImg + '/notSelect.png'">
                            </div>
                            <div>不再提示</div>
                        </div>
                        <!-- <img src="@/assets/images/home/update-notice-waring-icon.png">
                        <span>没有对应功能的请联系运营人员了解！</span> -->
                    </div>
                    <div class="sys-notice-bottom-right">
                        <!-- <div class="is-konw" @click="changeKnowing">
                            <div class="select-box">
                                <img v-if="notTip" :src="imgUrl + notice.noticeImg + '/selectOn.png'">
                                <img v-else :src="imgUrl + notice.noticeImg + '/notSelect.png'">
                            </div>
                            <div>已知晓且不再提醒</div>
                        </div> -->
                        <!-- <div class="img-line">
                            <img :src="imgUrl + notice.noticeImg + '/line.png'">
                        </div> -->
                        <div class="btn-box">
                           <div class="img-box" @click="changeTheme">
                                <img :src="imgUrl + notice.noticeImg +'/change-theme-btn.png'">
                            </div>
                            <div class="img-box" @click="handleClose">
                                <img :src="imgUrl + notice.noticeImg + '/experience-now-btn.png'">
                            </div>
                        </div>


                    </div>
                </div>
                <!-- <img class="notice-close" :src="imgClose" alt="close" @click="handleClose"> -->
            </div>
            </div>

        </dialog-wrapper>
    </div>
</template>

<script>
import { DialogWrapper } from "common-local"

export default {
    name: 'UpdateNotice',
    components: {
        DialogWrapper
    },
    inject:[
        'mainContainer'
    ],
    props: {
        fromPath: {
            type: String,
        }
    },
    data() {
        return {
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            dialogObj1: {
                title: "",
                dialogVisible: true,
                width: "auto",
            },
            notice: {},
            notTip: false,
            imgUrl:"https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/notice/notice-theme/",
        }
    },

    computed: {
    },
    watch: {},
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        init() {
            // this.getUpdateNotice()
            // console.log(this.fromPath,'=======fromPathfromPathfromPath=============')
            if (this.fromPath == '/login' || ['login', 'operationalEndAutoLogin'].includes(this.$route.query.source)) {
                this.getUpdateNotice()
            }else {
                this.clearUrlSource()
            }
        },
        /**
         * @Description: 切换选中状态
         * @Author: 王梦瑶
         * @Date: 2024-08-22 15:43:05
         */
         changeKnowing(){
            this.notTip = !this.notTip
         },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王梦瑶
         * @Date: 2024-08-21 17:46:47
         */
        changeTheme(){
            this.handleClose()
            this.mainContainer.$emit('updateThemeFlag',true)
        },
        // 获取是否有更新公告
        getUpdateNotice() {
            this._fet("/operate/operateNotice/takeEffectSystemRenew", {
                userType: '2' ,
                releaseTerminal:'1'
            }).then(res => {
                console.log("[getUpdateNotice]res:", res)
                if (res && res.data) {
                    if (res.data.code == "200" && res.data.data && res.data.data.id) {
                        this.notice = res.data.data
                        this.handleShow()
                    } else {
                        this.clearUrlSource()
                    }
                } else {
                    this.clearUrlSource()
                }
            })
        },
        // 展示更新公告
        handleShow() {
            this.dialogObj.dialogVisible = true
        },
        // 关闭更新公告
        handleClose() {
            if (this.notTip) {
                this._fet("/operate/operateNotice/noPromptAgainSystemRenew", {
                    noticeId: this.notice.id,
                    userType: '2'
                }).then(res => {
                    console.log("[24小时不再提示]res:", res)
                })
            }
            // this.clearUrlSource()
            this.dialogObj.dialogVisible = false
        },
        // 清除url上的标记
        clearUrlSource() {
            // let query = this._.cloneDeep(this.$route.query)
            // delete query.source
            // this.$router.replace({ query })
        },

    }
}
</script>

<style lang="scss">
.first-page-update-notice-dialog {
    .el-dialog {
        background-color: transparent;
        box-shadow: none;

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            margin: 0;
            padding: 0;
        }
    }
}
.first-page-sys-update-notice-dialog {
    .el-dialog__wrapper{
        padding: 0px !important;
    }
    .el-dialog {
        background-color: transparent;
        box-shadow: none;

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            margin: 0;
            padding: 0;
        }
    }
}
.first-page-sys-update-notice-dialog .el-dialog .el-dialog__body::-webkit-scrollbar {
    display: none !important;
}
</style>

<style lang="scss" scoped>

.el-dialog__wrapper{
    padding: 0px !important;
}
.update-notice{
    position: relative;
    .el-dialog__wrapper{
        padding: 0px !important;
    }
}
.is-konw{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #3D4B66;
    .select-box{
        display: flex;
        align-items: center;
        margin-right: 7px;
    }
}
.sys-notice-container-wrapper{
    position: relative;
}
.sys-notice-container {
    margin: 0 auto;
    width: 960px;
    height: 622px;
    box-sizing: border-box;
    padding: 0 33px;
    padding-top: 240px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center ;
    .sys-notice-header {
        width: 419px;
        object-fit: contain;
        display: block;
        z-index: 2;
        position: relative;
        box-sizing: border-box;
        padding-top: 120px;
        .sys-notice-header-title{
            width: 419px;
            height: 123px;
            box-sizing: border-box;
            padding: 0 58px;
            display: inline-block;
            font-family:  Microsoft YaHei;
            font-weight: bold;
            font-size: 48px;
            font-style: italic;
            line-height: 110px;
            background-image: url('@/assets/images/home/update-notice-title.png');
        }
        .sys-notice-header-date{
            width: 255px;
            height:124px;
            position: absolute;
            right: -34px;
            bottom: -40px;
            z-index: 3;
            background-image: url('@/assets/images/home/update-notice-date.png');
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: end;
            .sys-notice-header-date-word{
                margin-top: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #FEFEFE;
                width: 150px;
                padding-right: 15px;
                text-align: center;
                box-sizing: border-box;
            }
        }
    }

    .sys-notice-body {
        width: 100%;
        height: 310px;
        box-sizing: border-box;
        display: flex;
        padding: 26px 47px;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 6px;
        flex-direction: column;
        justify-content: space-between;
        .notice-content-tip{
            height: 37px;
            line-height: 37px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 21px;
            background-repeat: no-repeat;
            margin-bottom: 28px;
        }
        .sys-notice-content {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            flex: 1;
            overflow-y: auto;
        }
    }
    .sys-notice-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin-top: 10px;
        .sys-notice-bottom-left{
            padding-left: 55px;
            display: flex;
            align-items: center;
            color:#243E59;
            img{
                margin-right: 10px;
            }
        }

        .sys-notice-bottom-right{
            display: flex;
            align-items: center;
            // margin-top: 10px;

            .btn-box{
                display: flex;
                align-items: center;
            }
            .img-line{
                display: flex;
                align-items: center;
                margin: 0 16px;
            }
            .img-box{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .notice-close {
        width: 39px;
        height: 40px;
        object-fit: contain;
        display: block;
        cursor: pointer;
        margin: 24px auto 0;
    }
}
.btn-close{
    // position: absolute;
    // top: -20px;
    text-align: right;
    margin-bottom: -10px;
    img{
        height: 36px;
        width: 36px;
        border-radius: 50%;
        cursor: pointer;
    }
    // right: 0;
}
</style>
