<template>
    <div class="hm-title">
        <div class="hm-title__icon"></div>
        <div class="hm-title__content">
            {{ title }}
            <slot></slot>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: "hm-title",
    props: {
        title: {
            type: String,
            default: "",
        },
        statusNumber:{
            type: Number,
            default: 10,
        },
    },
};
</script>

<style scoped lang="scss">
.hm-title {
    display: flex;
    align-items: center;

    &__icon {
        width: 4px;
        height: 14px;
        background: #2166ee;
        border-radius: 2px;
        margin-right: 7px;
    }

    &__content {
        line-height: 12px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
    }
    &__noRead{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__no{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 20px;
        box-sizing: border-box;
        background: #4A87FF;
        border-radius: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }
    &__noNum{
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-left: 4px;
    }
}
</style>
